$(document).ready(function () {
    $('#widget_playBtn').circleProgress({
        value: 0.0,
        size: 140,
        animation: {
            duration: 100,
            easing: 'linear',
        },
        fill: {
            gradient: ['#00673d', '#00673d'],
        },
    });
    $('.hero_slider').slick({
        autoplay: false,
        autoplaySpeed: 4000,
        nextArrow: '<i class="nextarrow fa fa-chevron-right"></i>',
        prevArrow: '<i class="prevarrow fa fa-chevron-left"></i>',
    });
});
